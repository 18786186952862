<template>
  <a-modal v-model="visible" title="审核应用" on-ok="handleOk">
    <template slot="footer">
      <a-button key="cancel" @click="handleCancel"> 取消 </a-button>
      <a-button
        key="reject"
        type="danger"
        :loading="loading"
        @click="handleReject"
      >
        不通过
      </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        @click="handleOk"
      >
        通过
      </a-button>
    </template>
    <template v-if="detail">
      <p>应用名称：{{ detail.app_name }}</p>
      <p>应用地址：{{ detail.url }}</p>
      <p>应用简介：{{ detail.summary }}</p>
    </template>
  </a-modal>
</template>

<script>
import { APP_REVIEW_STATUS } from '@/config/const.js';

export default {
  name: 'ReviewModal',
  data() {
    return {
      visible: false,
      detail: null,
      loading: false,
    };
  },
  methods: {
    open(detail) {
      this.detail = detail;
      this.visible = true;
    },
    handleOk() {
      this.review(APP_REVIEW_STATUS.APPROVED.value);
    },
    handleCancel() {
      this.visible = false;
      this.detail = null;
    },
    handleReject() {
      this.review(APP_REVIEW_STATUS.NOT_APPROVED.value);
    },
    async review(review_status) {
      this.loading = true;
      const { err, res } = await this.$api.app.review({
        _id: this.detail._id,
        review_status,
      });

      if (!err) {
        this.$message.success(res.msg);
        this.visible = false;
        this.$emit('done', { _id: this.detail._id, review_status });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
